.white-space-nowrap {
  white-space: nowrap;
}

// .table > thead > tr > th {
//   padding-top: 0.625rem !important;
//   padding-bottom: 0.625rem !important;
//   line-height: 1;
//   color: var(--#{$prefix}gray-700);
//   &:first-child {
//     padding-left: 0;
//   }
//   &:last-child {
//     padding-right: 0;
//   }
// }
.table {
  > thead {
    > tr {
      > th {
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
        line-height: 1;
        // color: var(--#{$prefix}gray-700);
        // color: var(--#{$prefix}gray-900);

        // &:first-child {
        //   padding-left: 0;
        // }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  > tbody {
    > tr {
      > td {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  // &.table-dark{
  //   color: $gray-400 !important;
  //   thead{
  //     tr{
  //       th{
  //         color: $gray-400 !important;
  //       }
  //     }
  //   }
  // }
  // table varient
  @each $color, $value in $theme-colors {
    @if $color == 'dark' {
      .table-#{$color},
      &.table-#{$color} {
        --#{$prefix}table-hover-bg: rgba(
          var(--#{$prefix}primary-600-rgb),
          0.07
        );
        color: $gray-400;
      }
    } @else {
      .table-#{$color},
      &.table-#{$color} {
        color: $gray-900;
        border-color: $gray-300;
      }
    }
  }
}
