.form-label {
  padding-left: $input-padding-x;
  line-height: $line-height-sm;
  text-transform: uppercase;
  margin-bottom: 0;
  .form-check & {
    padding-left: 0 !important;
  }
}
.form-control {
  &.form-control-underlined {
    border: none;
    border-bottom: 1px solid;
    border-color: $input-border-color;
    border-radius: 0;
    padding: $input-padding-y 0 !important;
    box-shadow: none;
    &:focus {
      border-color: var(--#{$prefix}primary) !important;
    }
  }
  &::file-selector-button {
    background-color: var(--#{$prefix}input-group-addon-bg) !important;
  }
  &:disabled {
    &::file-selector-button {
      background-color: var(
        --#{$prefix}input-disabled-group-addon-bg
      ) !important;
      color: var(--#{$prefix}input-disabled-group-addon-color);
    }
  }
}
// .form-control::file-selector-button {
//   background-color: red !important;
// }

// Floating Labels

// Floating Labels
.form-floating {
  > label {
    color: var(--#{$prefix}gray-800);
    font-weight: $font-weight-bold;
    font-size: map-get($font-sizes, '-2');
    text-transform: uppercase;
  }
  .form-control {
    box-shadow: none;
  }
  .form-control-underlined {
    + label {
      padding: 0;
    }
  }
}

.form-check-label {
  font-weight: $font-weight-semi-bold;
  font-size: map-get($font-sizes, '-1');
}

.form-check-light {
  .form-check-input {
    &:checked {
      background-color: $form-check-light-input-checked-bg-color;
      border-color: $form-check-light-input-checked-border-color;

      &[type='checkbox'] {
        @if $enable-gradients {
          background-image: escape-svg($form-check-light-input-checked-bg-image),
            var(--#{$prefix}gradient);
        } @else {
          background-image: escape-svg(
            $form-check-light-input-checked-bg-image
          );
        }
      }
    }
  }
}

.form-check-lg {
  padding-left: $form-check-padding-start-lg;

  .form-check-input {
    width: $form-check-input-width-lg;
    height: $form-check-input-width-lg;
    margin-left: $form-check-padding-start-lg * -1;
  }
}

.form-switch {
  .form-check-input {
    border: none;
    background-color: var(--#{$prefix}gray-400);

    &:checked {
      background-color: $primary;
    }

  }
}

.form-switch-lg {
  padding-left: $form-switch-padding-start-lg;

  .form-check-input {
    width: $form-switch-width-lg;
    height: $form-check-input-width-lg;
    margin-left: $form-switch-padding-start-lg * -1;
  }
}

.form-icon-container {
  position: relative;
  .form-icon-input,
  .form-icon-label {
    padding-left: map-get($spacers, 6);
  }
  .form-icon {
    position: absolute;
    top: 12px;
    left: $input-padding-x;
  }
  .form-floating {
    .form-icon-input {
      padding-left: 35px;
    }
    + {
      .form-icon {
        top: 1.0625rem;
      }
    }
  }
}

.form-check-input,
.form-select {
  appearance: none;
  -webkit-appearance: none;
}

/*-----------------------------------------------
|   Remove spinner from Input[type="number"]
-----------------------------------------------*/
.input-spin-none::-webkit-outer-spin-button,
.input-spin-none::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-spin-none[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
.form-check-line-through {
  & + label {
    transition: $transition-base;
  }
  &:checked + label {
    color: var(--#{$prefix}gray-500);
    text-decoration: line-through;
  }
}

/*-----------------------------------------------
|   Remove Arrows from Input[type="number"]
-----------------------------------------------*/

.number-arrows-none {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/*-----------------------------------------------
|   Remove calendar icon from Input[type="date"]
-----------------------------------------------*/

.inventory-attributes {
  input[type='date']::-webkit-calendar-picker-indicator {
    background-image: none;
  }
}

.input-group {
  .input-group-text {
    background-color: var(--#{$prefix}input-group-addon-bg) !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // border: 1px solid green;
  // -webkit-text-fill-color: green;
  // color: white !important;
  // -webkit-box-shadow: 0 0 0px 1000px var(--#{$prefix}input-bg) inset;
  // transition: all 5000s ease-in-out 0s;
}

.form-check-input-todolist {
  &:checked {
    + {
      div {
        label {
          color: var(--#{$prefix}gray-500);
          text-decoration: line-through;
        }
      }
    }
  }
}
.dark{
  .navbar-dark{
    &.light{
      .form-control{
        background-color: #{map-get($grays, '1100')};;
        border-color: #{map-get($dark-grays, '300')};;
      }
    }

  }
}
